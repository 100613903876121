$color-light1: #F0EBE3;
$color-light2: #E4DCCF;
$color-dark1: #7D9D9C;
$color-dark2: #576F72;
$color-dark3: #a2bebe;
$color-dark4: #d6e2e2;
$color-dark5: #4e6568;

/*************************************************/
/*********** MOBILE PHONE CSS SETTINGS ***********/
/*************************************************/
@media only screen and (max-width: 767px) {}


/*************************************************/
/************** TABLET CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .container-oglasi {
        width: 85%;
        margin: auto;

        .reklama {
            width: 450px;
            margin: auto;
        }

        .oglasi {
            display: flex !important;
            flex-wrap: wrap !important;
            flex-direction: row !important;
            justify-content: center !important;

            .oglas {
                width: 300px;
                margin: 7px;
            }
        }
    }
}


/*************************************************/
/************* DESKTOP CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 1000px) {
    .container-oglasi {
        width: 75%;
        margin: auto;

        .reklama {
            width: 450px;
            margin: auto;
        }

        .oglasi {
            display: flex !important;
            flex-wrap: wrap !important;
            flex-direction: row !important;
            justify-content: center !important;

            .oglas {
                width: 325px;
                margin: 7px;
            }
        }
    }
}

/*************************************************/
.oglasi-login {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: $color-dark2;
    font-weight: 500;
    font-size: 1.1rem;

    a {
        color: $color-dark1;
        font-weight: 500;
        margin-left: 20px;
        border: 2px solid $color-dark1;
        border-radius: 7px;
        padding: 5px 10px;
        text-decoration: none;
    }

    a:hover {
        color: white;
        background-color: $color-dark1;
    }
}

.cena-oglasavanja {
    margin: 10px 10px;
    padding: 10px;
    border: 2px solid $color-dark1;
    border-radius: 10px;
    box-shadow: 3px 3px 3px rgb(65, 65, 65);
    background-color: $color-dark4;
    text-align: center;

    h5,
    h6 {
        color: $color-dark5;
        text-align: center;
    }

    h6 {
        margin-top: 10px;
        margin-bottom: 0;
    }

    p {
        font-weight: 500;
        color: $color-dark5;
        margin: 0 10px;
    }

    a {
        text-decoration: none;
        color: $color-dark5;
        font-size: 1.2rem;
    }
}

.oglasi {

    span {
        border: none;
    }

    .oglas {
        margin: 10px 10px;
        padding: 5px;
        border: 2px solid $color-dark1;
        border-radius: 10px;
        box-shadow: 3px 3px 3px rgb(65, 65, 65);
        display: flex;
        flex-direction: row;

        img {
            width: 130px;
            height: 95px;
        }

        .divright {
            margin-left: 7px;

            h5 {
                font-size: 17px;
                margin-bottom: 3px;
            }

            p {
                margin-bottom: 0;
                line-height: 1.35;
            }

            h6 {
                margin-top: 7px;
            }

        }
    }
}

.cena {
    font-size: 1.35rem;
    color: red;
    font-weight: 500;
    text-align: center;
}

.cena-cards {
    color: red;
}
.osoba-mesto {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.person,
.lokacija {
    color: rgb(64, 128, 40);
    font-size: 1.5rem;
    margin-right: 5px;
}

.oglasivac,
.mesto {
    display: flex;
    flex-direction: row;
}

.telefon {
    text-align: center;
    margin-top: 0;
}