@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

$color-light1: #F0EBE3;
$color-light2: #E4DCCF;
$color-dark1: #7D9D9C;
$color-dark2: #576F72;
$color-dark3: #a2bebe;


section {
    // width: 100%;
    // max-width: 420px;
    // min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 2rem;
    background-color: $color-dark3;

    h1 {
        color: $color-dark2;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        flex-grow: 1;
        padding-bottom: 1rem;

        label {
            color: $color-dark2;
            font-weight: 700;
            margin-bottom: 0.25rem;
        }

        input[type="text"],
                                                                                                                                input[type="email"],
        input[type="password"],
        button,
        textarea {
            font-family: 'Nunito', sans-serif;
            font-size: 22px;
            padding: 0.25rem;
            border: 2px solid $color-dark2;
            border-radius: 0.5rem;
        }

        button {
            margin-top: 1rem;
            padding: 0.5rem;
            cursor: pointer;
        }

                                .form-button {
                                    color: $color-dark2;
                                    font-weight: 700;
                                }
        .pass-visible {
            display: flex;
            flex-direction: row;

            .pass-vis-color {
                accent-color: $color-dark3;
                margin-left: 0.5rem;
            }
        }
    }

                                                                .vec-registrovan,
                                                                .treba-registracija {
        color: $color-dark2;
        font-weight: 700;

        span {
            font-size: 1.25rem;
        }
    }
}



a {
    color: #fff;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: rgb(21, 92, 21);
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}
input[type="text"]:focus,
input[type="email"]:focus,
input[type="password"]:focus {
    border: 2px solid $color-dark2 !important;
    outline: none !important;
}