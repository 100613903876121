/*************************************************/
/*********** MOBILE PHONE CSS SETTINGS ***********/
/*************************************************/
@media only screen and (max-width: 767px) {
    iframe {
        height: 35vh;
    }
}


/*************************************************/
/************** TABLET CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 768px) and (max-width: 999px) {
    iframe {
        height: 50vh;
    }
}


/*************************************************/
/************* DESKTOP CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 1000px) {
    iframe {
        height: 75vh;
    }
}