$color-light1: #F0EBE3;
$color-light2: #E4DCCF;
$color-dark1: #7D9D9C;
$color-dark2: #576F72;

.weatherinfo {
    width: 100%;
    display: flex;
    flex-direction: column;

    .weather_table {
        width: 100%;
        height: 75vh;
        border-bottom: 2px solid $color-dark1;
    }

    .weather_meteogram {
        width: 100%;
        height: 40vh;
            margin-top: 10px;
    }
}