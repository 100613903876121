$color-light1: #F0EBE3;
$color-light2: #E4DCCF;
$color-dark1: #7D9D9C;
$color-dark2: #576F72;

/*************************************************/
/*********** MOBILE PHONE CSS SETTINGS ***********/
/*************************************************/
@media only screen and (max-width: 767px) {
    .navigation-bar {
            width: 100%;
            background-color: $color-dark1;
            padding-top: 9px;
        padding-bottom: 9px;
        white-space: nowrap;
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;
            border-bottom: 4px solid $color-light1;

        button {
                cursor: pointer;
                font-size: 2.75rem;
                width: 75px;
                height: 75px;
                color: $color-light1;
                background-color: $color-dark1;
                border: 3px solid $color-light1;
                border-radius: 40px;
                margin-right: 9px;
                padding: 0.5rem;
                /* center the text content */
                display: inline-flex;
                justify-content: center;
                align-items: center;
            }

                                                                                                                                .active {
                                                                                                                                    font-size: 2.75rem;
                                                                                                                                    width: 75px;
                                                                                                                                    height: 75px;
                                                                                                                                    color: $color-dark1;
                                                                                                                                    background-color: $color-light1;
                                                                                                                                    border: 3px solid $color-light1;
                                                                                                                                    border-radius: 40px;
                                                                                                                                    margin-right: 9px;
                                                                                                                                    padding: 0.5rem;
                                                                                                                                }
.home-button {
    margin-left: 9px;
}
}
}


/*************************************************/
/************** TABLET CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 768px) and (max-width: 999px) {

    .navigation-bar {
        width: 100%;
        background-color: $color-dark1;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 4px solid $color-light1;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        button {
            cursor: pointer;
            font-size: 2.75rem;
            width: 100px;
            height: 100px;
            color: $color-light1;
            background-color: $color-dark1;
            border: 4px solid $color-light1;
            border-radius: 55px;
            margin-right: 9px;
            padding: 0.5rem;
            /* center the text content */
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        .active {
            font-size: 2.75rem;
            width: 100px;
            height: 100px;
            color: $color-dark1;
            background-color: $color-light1;
            border: 3px solid $color-light1;
            border-radius: 55px;
            margin-right: 9px;
            padding: 0.5rem;
        }

        .home-button {
            margin-left: 9px;
        }
    }
}


/*************************************************/
/************* DESKTOP CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 1000px) {

    .navigation-bar {
        width: 75%;
        margin: auto;
        background-color: $color-dark1;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 4px solid $color-light1;
        display: flex;
        flex-direction: row;
        justify-content: center;

        button {
            cursor: pointer;
            font-size: 2.75rem;
            width: 100px;
            height: 100px;
            color: $color-light1;
            background-color: $color-dark1;
            border: none;
            // border: 3px solid $color-light1;
            // border-radius: 55px;
            margin-right: 9px;
            padding: 0.5rem;
            /* center the text content */
            display: inline-flex;
            justify-content: center;
            align-items: center;
        }

        .active {
            font-size: 2.75rem;
            width: 100px;
            height: 100px;
            color: $color-dark1;
            background-color: $color-light1;
            border: 3px solid $color-light1;
            border-radius: 55px;
            margin-right: 9px;
            padding: 0.5rem;
        }

        .home-button {
            margin-left: 9px;
        }
    }
}