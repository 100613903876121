$color-light1: #f0ebe3;
$color-light2: #e4dccf;
$color-dark1: #7d9d9c;
$color-dark2: #576f72;

.footer-container {
    border-top: 4px solid $color-light1;

.footer {
    background-color: $color-dark1;
    color: $color-light1;
    width: 100%;
    height: 3.5rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
}

/*************************************************/
/************* DESKTOP CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 1000px) {
    .footer-container {
    border-top: 4px solid $color-light1;
    width: 75%;
        margin: auto;
    
    }
}