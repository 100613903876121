$color-light1: #F0EBE3;
$color-light2: #E4DCCF;
$color-dark1: #7D9D9C;
$color-dark2: #576F72;

/*************************************************/
/*********** MOBILE PHONE CSS SETTINGS ***********/
/*************************************************/
@media only screen and (max-width: 767px) {
.cont {
    width: 100%;

    .weather {

        .weather_card {
                width: 100%;
                height: 175px;
        }

                .otvori-weather {
            display: flex;
            justify-content: end;

            a {
                    text-decoration: none;
                    color: $color-dark2;
                font-weight: 500;
                font-size: 1.1rem;
                    margin-right: 10px;
            }
        }
        
    }

                .apartmani,
                .oglasi {

        span {
                border: none;
            }
                                .apartman,
                                .oglas {
            margin: 10px 10px;
            padding: 5px;
            border: 2px solid $color-dark1;
            border-radius: 10px;
            box-shadow: 3px 3px 3px rgb(65, 65, 65);
            display: flex;
                flex-direction: row;
            
                img {
                    width: 130px;
                    height: 95px;
                }
            
                .divright {
                    margin-left: 7px;
            
                    h5 {
                        font-size: 19px;
                            margin-bottom: 3px;
                    }
            
                    p {
                        margin-bottom: 0;
                        line-height: 1.35;
                    }
            
                }
        }
                                                                }

                                .otvori-smestaj,
                                .otvori-oglase {
            display: flex;
            justify-content: end;

a {
        text-decoration: none;
        color: $color-dark2;
        font-weight: 500;
        font-size: 1.1rem;
        margin-right: 10px;
    }
}

    .restorani {

        .restoran {
            margin: 10px 10px;
            padding: 10px;
            border: 2px solid $color-dark1;
            border-radius: 10px;
            box-shadow: 3px 3px 3px rgb(65, 65, 65);
        }

        .otvori-restorani {
            display: flex;
            justify-content: end;

            a {
                text-decoration: none;
                color: $color-dark2;
                font-weight: 500;
                font-size: 1.1rem;
                margin-right: 10px;
            }
        }
    }


    .vesti {

        .vest {
            margin: 10px 10px;
            padding: 10px;
            border-bottom: 1px solid $color-dark1;
            // border: 2px solid $color-dark1;
            // border-radius: 10px;
            // box-shadow: 3px 3px 3px rgb(65, 65, 65);
        }

        .otvori-info {
            display: flex;
            justify-content: end;

            a {
                text-decoration: none;
                color: $color-dark2;
                font-weight: 500;
                font-size: 1.1rem;
                margin-right: 10px;
            }
        }
    }
}
}
/*************************************************/
/************** TABLET CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 768px) and (max-width: 999px) {
    .cont {
        width: 75%;
        margin: auto;

        .weather {

            .weather_card {
                width: 100%;
                height: 175px;
            }

            .otvori-weather {
                display: flex;
                justify-content: end;

                a {
                    text-decoration: none;
                    color: $color-dark2;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-right: 10px;
                }
            }

        }

        .apartmani,
        .oglasi {

            span {
                border: none;
            }

            .apartman,
            .oglas {
                margin: 10px 10px;
                padding: 5px;
                border: 2px solid $color-dark1;
                border-radius: 10px;
                box-shadow: 3px 3px 3px rgb(65, 65, 65);
                display: flex;
                flex-direction: row;

                img {
                    width: 130px;
                    height: 95px;
                }

                .divright {
                    margin-left: 7px;

                    h5 {
                        font-size: 19px;
                        margin-bottom: 3px;
                    }

                    p {
                        margin-bottom: 0;
                        line-height: 1.35;
                    }

                }
            }


        }

        .otvori-smestaj,
        .otvori-oglase {
            display: flex;
            justify-content: end;

            a {
                text-decoration: none;
                color: $color-dark2;
                font-weight: 500;
                font-size: 1.1rem;
                margin-right: 10px;
            }
        }

        .restorani {

            .restoran {
                margin: 10px 10px;
                padding: 10px;
                border: 2px solid $color-dark1;
                border-radius: 10px;
                box-shadow: 3px 3px 3px rgb(65, 65, 65);
            }

            .otvori-restorani {
                display: flex;
                justify-content: end;

                a {
                    text-decoration: none;
                    color: $color-dark2;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-right: 10px;
                }
            }
        }


        .vesti {

            .vest {
                margin: 10px 10px;
                padding: 10px;
                border-bottom: 1px solid $color-dark1;
                // border: 2px solid $color-dark1;
                // border-radius: 10px;
                // box-shadow: 3px 3px 3px rgb(65, 65, 65);
            }

            .otvori-info {
                display: flex;
                justify-content: end;

                a {
                    text-decoration: none;
                    color: $color-dark2;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-right: 10px;
                }
            }
        }
    }
}


/*************************************************/
/************* DESKTOP CSS SETTINGS **************/
/*************************************************/
@media only screen and (min-width: 1000px) {
    .cont {
        width: 75%;
        margin: auto;

        .reklama {
            width: 450px;
            margin: auto;
        }

        .weather {

            .weather_card {
                width: 100%;
                height: 175px;
            }

            .otvori-weather {
                display: flex;
                justify-content: end;

                a {
                    text-decoration: none;
                    color: $color-dark2;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-right: 10px;
                }
            }

        }

        .apartmani,
        .oglasi {

            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: center;

            span {
                border: none;
            }

            .apartman,
            .oglas {
                width: 350px;
                margin: 10px 10px;
                padding: 5px;
                border: 2px solid $color-dark1;
                border-radius: 10px;
                box-shadow: 3px 3px 3px rgb(65, 65, 65);
                display: flex;
                flex-direction: row;

                img {
                    width: 130px;
                    height: 95px;
                }

                .divright {
                    margin-left: 7px;

                    h5 {
                        font-size: 19px;
                        margin-bottom: 3px;
                    }

                    p {
                        margin-bottom: 0;
                        line-height: 1.35;
                    }

                }
            }


        }

        .otvori-smestaj,
        .otvori-oglase {
            display: flex;
            justify-content: end;

            a {
                text-decoration: none;
                color: $color-dark2;
                font-weight: 500;
                font-size: 1.1rem;
                margin-right: 10px;
            }
        }

        .restorani {

            .restoran {
                margin: 10px 10px;
                padding: 10px;
                border: 2px solid $color-dark1;
                border-radius: 10px;
                box-shadow: 3px 3px 3px rgb(65, 65, 65);
            }

            .otvori-restorani {
                display: flex;
                justify-content: end;

                a {
                    text-decoration: none;
                    color: $color-dark2;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-right: 10px;
                }
            }
        }


        .vesti {

            .vest {
                margin: 10px 10px;
                padding: 10px;
                border-bottom: 1px solid $color-dark1;
                // border: 2px solid $color-dark1;
                // border-radius: 10px;
                // box-shadow: 3px 3px 3px rgb(65, 65, 65);
            }

            .otvori-info {
                display: flex;
                justify-content: end;

                a {
                    text-decoration: none;
                    color: $color-dark2;
                    font-weight: 500;
                    font-size: 1.1rem;
                    margin-right: 10px;
                }
            }
        }
    }
}